import * as braze from '@braze/web-sdk';
import { logError } from './Logger';

let brazeKey = '';
let brazeEndpoint = process.env.REACT_APP_BRAZE_ENDPOINT as string;

// The below three variables will adjust based on the environment
if (process.env.REACT_APP_ENV === 'staging') {
  brazeKey = process.env.REACT_APP_STAGING_BRAZE_API_KEY as string;
}
if (process.env.REACT_APP_ENV?.includes('prod')) {
  brazeKey = process.env.REACT_APP_PROD_BRAZE_API_KEY as string;
}
if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
  brazeKey = process.env.REACT_APP_DEV_BRAZE_API_KEY as string;
}

braze.initialize(brazeKey, {
  baseUrl: brazeEndpoint,
  enableLogging: false,
});

const loginBrazeUser = (userId?: string, email?: string, phoneNumber?: string): void => {
  // Check if Braze SDK is initialized
  if (!braze) {
    logError('Braze SDK not initialized', 'ALERT');
    return;
  } else if (!userId || userId.length === 0) {
    logError('Braze user ID not valid', 'ALERT');
    return;
  } else {
    // Change to the new user
    braze.changeUser(userId);
    braze.openSession();
    if (email && email.length !== 0) {
      braze.getUser()?.setEmail(email);
    }
    if (phoneNumber && phoneNumber.length !== 0) {
      braze.getUser()?.setPhoneNumber(phoneNumber);
    }
  }
};

export { loginBrazeUser };
