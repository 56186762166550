/* eslint-disable no-async-promise-executor */
import qs from 'query-string';
import type { CardType } from '../types';
import request from './Request';

const timeOut = 10000;

export type SmfaVerificationAPIResponseType = {
  status: string;
};

export type EnrollmentStatusAPIResponseType = {
  enrollmentStatus: string;
  enrollmentType: string;
  siteRentReportingStatus: string;
  accountStatus: string;
  accountReportingStatus: string;
};

export type SSNStatusAPIResponseType = {
  hasSSN: boolean;
};

export type CreateAccountAPIResponseType = {
  id: string;
  message: string;
};

export type CreateAccountAPIRequestType = {
  sessionId: string;
};

export type ConsentAPIResponseType = {
  status?: string;
  message: string;
  code?: string;
};

export type ConsentAPIRequestType = {
  accountId?: string;
  multiTenancyKey?: string;
  profileId?: string;
  reasons?: string[];
  status?: string;
};

export type SampleAPIResponseType = {
  status: string;
};

export type SampleAPIRequestType = {
  firstName?: string;
  email?: string;
  lasttName?: string;
  otp?: string;
  id?: string;
};

export type Status = {
  status: boolean;
};

export type UserData = {
  firstName?: string;
  lastName?: string;
  ssn: string;
  dob?: string;
  zipCode: string;
  intendedPersonId?: string;
  intendedMtk?: string;
};

export type userResponse = {
  id: string;
  maskedEmail: string;
  maskedPhoneNumber: string;
  isSignedUp: boolean;
  shouldGetFullSSN: boolean;
};

export type GenOtpResponse = {
  message: string;
  method: string;
};

export type Address = {
  city?: string;
  countryCode?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
  isManagedResident?: boolean;
};

export type SessionInfoResponse = {
  maskedEmail?: string;
  maskedPhoneNumber?: string;
  address?: Address;
};

export type verificationResponse = {
  verified: boolean;
  address: Address;
};

export type creditScoreResponseType = {
  scores: {
    score: any;
    date: string;
    provider: string;
  }[];
  optInTimestamp: string;
};

interface TaxFilingStatus {
  percentageCompleted: number;
  taxFilingStatus: string;
  estimatedTaxRefund: number;
  disqualificationReason: string;
  isUserDisqualified: boolean;
}

export type AccountInfoResponseType = {
  addressLine1: string;
  addressLine2: string;
  email: string;
  city: string;
  state: string;
  postalCode: string;
  unit: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isManagedResident?: boolean;
  ShowCreditScore?: boolean;
  DitVerificationStatus?: boolean;
  SmfaVerificationStatus?: boolean;
  mtk?: string;
  taxFilingStatus?: TaxFilingStatus;
  brazeId?: string;
};

export type AvailableFeaturesResponseType = string[];

export type UpdateEmailRequestType = {
  email: string;
  password: string;
};

export type UpdatePasswordRequestType = {
  password: string;
  oldPassword: string;
};

export type AccountVerificationEmailResponseType = {
  email: string;
  message: string;
};

export interface CreditScoreData {
  score: number;
  date: string;
  provider: string;
}

export interface ScoreReason {
  code: string;
  title: string;
  description?: string;
}

export interface CreditScoreFactorResponse {
  person_id: string;
  mtk: string;
  credit_score: number;
  credit_usage: number;
  late_payments: number;
  credit_limit: number;
  available_credit: number;
  account_balances: number;
  inquiries: number;
  avg_account_age: number;
  oldest_account_age: number;
  /** datetime string */
  report_generated_at: string;
  score_reasons: ScoreReason[];
}

export type BrazeSubscriptionStatus = 'unsubscribed' | 'subscribed';
export interface BrazeSubscription {
  /**
   * Used when rendering in the UI
   * - can return empty string
   */
  name?: string;
  /**
   * Used when rendering in the UI
   * - can return empty string
   */
  description?: string;
  status: BrazeSubscriptionStatus;
  subscriptionGroupId: string;
}

export interface GetBrazeSubscriptionsResponse {
  email: BrazeSubscription[];
  push: BrazeSubscription[];
  sms: BrazeSubscription[];
}

export interface UpdateBrazeSubscriptionsRequest {
  notificationChannel: 'email' | 'sms';
  subscriptionGroupId: string;
  status: BrazeSubscriptionStatus;
}

export type CreditScoreHistoryResponseType = {
  scores: CreditScoreData[];
  optInTimestamp: string;
};

export type RentersHistoryResponseType = {
  accountStatus: string;
  timestamp: string;
  openDate: string;
  closedDate: string;
  address: {
    street: string;
    unit: string;
    city: string;
    state: string;
    postalCode: string;
  };
  reportingHistory: {
    period: string;
    status: string;
  }[];
}[];

export type ShowScoreAPIResponseType = {
  ShowCreditScore: boolean;
  message: string;
  code?: string;
};

export type ShowScoreAPIRequestType = {
  ShowCreditScore: boolean;
};

export type UpdateAddressAPIRequestType = {
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  unit?: string;
};

export type UpdateNameAPIRequestType = {
  firstName?: string;
  lastName?: string;
};

export type UpdatePhoneNumberAPIRequestType = {
  phoneNumber: string;
  ssn?: string;
};

export type UpdatePhoneNumberAPIResponseType = {
  transactionId: string;
  correlationId?: string;
  referenceTransactionId?: string;
  timestamp?: string;
  unit?: string;
  decision: string;
  originalTransactionId?: string;
};

export type UpdateAddressAPIResponseType = {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  unit: string;
  message?: string;
  code?: string;
};

export type GetSMFAOptionsResponseType = {
  email: string;
  phoneNumber: string;
};

export type InitiateSMFARequestType = {
  method: string;
};

export type InitiateSMFAResponseType = {
  status: string;
  sessionId: string;
};

export type UpdateSsnAPIRequestType = {
  ssn?: string;
};
export type IndependentUserAPIRequestType = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
};
export type LogoutResponseType = {
  message: string;
};

export type DisplayPlatform = 'web' | 'mobile' | 'both';

export type BannerType = {
  active: boolean;
  dateStart: string;
  dateEnd: string;
  header: string;
  id: string;
  content: string;
  backgroundColor: string;
  button: boolean;
  buttonColor: string;
  buttonText: string;
  buttonLink: string;
  buttonNewTab: boolean;
  additionalContent?: any;
  smImage: string;
  mdImage: string;
  displayPlatform: DisplayPlatform;
  inProgressButtonText: string;
  inProgressText: string;
  mtks: string[];
};

export const RentPaymentStatus = {
  CONFIRMED: 'CONFIRMED',
  UNCONFIRMED: 'UNCONFIRMED',
  MISSED: 'MISSED',
} as const;

export interface Payment {
  period?: string;
  date: string;
  amountPaid: number;
  amountOwed: number;
  status: keyof typeof RentPaymentStatus;
  note?: string;
}

export type PaymentHistoryResponseType = {
  firstName: string;
  lastName: string;
  leaseStart: string;
  leaseEnd: string;
  address: {
    street: string;
    unit: string;
    city: string;
    state: string;
    postalCode: string;
  };
  dateUpdated: string;
  payments: Payment[];
  paymentHistorySummary: {
    totalCompleted: number;
    length: number;
    monthlyRentalObligation: number;
  };
};

export type GeneratePdfRequestType = {
  recepientEmail: string[];
};

interface IAPI {
  GetSampleAPI(id: SampleAPIRequestType): Promise<SampleAPIResponseType>;
  ValidateUser(userdata: UserData): Promise<userResponse>;
  GenOtpRequest(id: string, method: string): Promise<GenOtpResponse>;
  VerifyCode(id: string, method: string, otp: string): Promise<verificationResponse>;
  GetSessionInfo(id: string): Promise<SessionInfoResponse>;
  GetEnrollmentStatus(): Promise<EnrollmentStatusAPIResponseType>;
  GetSSNStatus(): Promise<SSNStatusAPIResponseType>;
  GetSmfaVerificationStatus(sessionId: string): Promise<SmfaVerificationAPIResponseType>;
  updateResidentConsent(consentData: ConsentAPIRequestType): Promise<ConsentAPIResponseType>;
  showCreditScore(showScoreData: ShowScoreAPIRequestType): Promise<ShowScoreAPIResponseType>;
  updateAddress(updateAddress: UpdateAddressAPIRequestType): Promise<UpdateAddressAPIResponseType>;
  /**
   * this is the update account info path
   * - it allows updating the name and phone number
   * - phone number is optional and there is already an existing
   * update phone number endpoint, so using this to only update name
   */
  updateName(updateName: UpdateNameAPIRequestType): Promise<unknown>;
  updateSsn(updateSsn: UpdateSsnAPIRequestType): Promise<unknown>;
  createIndependentUser(createIndependentuser: IndependentUserAPIRequestType): Promise<unknown>;
  updatePhoneNumber(
    phoneNumber: UpdatePhoneNumberAPIRequestType,
  ): Promise<UpdatePhoneNumberAPIResponseType>;
  createAccount(sessionId: CreateAccountAPIRequestType): Promise<CreateAccountAPIResponseType>;
  GetCards(): Promise<CardType[]>;
  GetCreditScore(
    startTimestamp?: string,
    endTimestamp?: string,
    sort?: string,
  ): Promise<creditScoreResponseType>;
  GetSMFAOptions(): Promise<GetSMFAOptionsResponseType>;
  InitiateSMFA(initiateSMFARequest: InitiateSMFARequestType): Promise<InitiateSMFAResponseType>;
  UpdateEmail(updateEmailData: UpdateEmailRequestType): Promise<UpdateEmailRequestType>;
  UpdatePassword(updatePasswordData: UpdatePasswordRequestType): Promise<UpdatePasswordRequestType>;
  GetAccountInfo(): Promise<AccountInfoResponseType>;
  GetAvailableFeatures(): Promise<AvailableFeaturesResponseType>;
  SendAccountVerificationEmail(): Promise<AccountVerificationEmailResponseType>;
  GetCreditScoreHistory(
    startTimestamp?: string,
    endTimestamp?: string,
    sort?: string,
    limit?: number,
  ): Promise<CreditScoreHistoryResponseType>;
  GetPaymentHistory(): Promise<PaymentHistoryResponseType>;
  GetRentersHistory(): Promise<RentersHistoryResponseType>;
  Logout(): Promise<LogoutResponseType>;
  GetCards(): Promise<CardType[]>;
  GetBanners(): Promise<BannerType[]>;
  RegisterApril(): Promise<unknown>;
  GeneratePdf(recepientEmail: GeneratePdfRequestType): Promise<unknown>;
  deleteAccount(encryptedPassword: string): Promise<unknown>;
  getCreditScoreFactors(): Promise<CreditScoreFactorResponse>;
  getBrazeSubscriptions(): Promise<GetBrazeSubscriptionsResponse>;
  updateBrazeSubscriptions(updatedSubscriptions: UpdateBrazeSubscriptionsRequest): Promise<unknown>;
}

export const TestAPI: IAPI = {
  GetSampleAPI: (requestData: SampleAPIRequestType) =>
    new Promise<SampleAPIResponseType>((resolve) => {
      const status = requestData.firstName && requestData.lasttName ? 'In Progress' : 'PENDING';
      setTimeout(
        () =>
          resolve({
            status: status,
          }),
        timeOut,
      );
    }),

  GetEnrollmentStatus: () =>
    new Promise<EnrollmentStatusAPIResponseType>((resolve) => {
      setTimeout(
        () =>
          resolve({
            enrollmentStatus: 'test',
            enrollmentType: 'test',
            siteRentReportingStatus: 'test',
            accountStatus: 'test',
            accountReportingStatus: 'test',
          }),
        timeOut,
      );
    }),
  GetSmfaVerificationStatus: () =>
    new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          status: 'Pending ',
        };
        resolve(data);
      }, timeOut);
    }),
  GetSSNStatus: () =>
    new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          hasSSN: true,
        };
        resolve(data);
      }, timeOut);
    }),
  GetSMFAOptions: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          email: 'test',
          phoneNumber: 'test',
        };
        resolve(data);
      }, timeOut);
    });
  },
  InitiateSMFA: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          status: 'test',
          sessionId: 'test',
        };
        resolve(data);
      }, 3000);
    });
  },
  ValidateUser: () =>
    new Promise<userResponse>((resolve) => {
      setTimeout(
        () =>
          resolve({
            id: 'ce2aefbf-c3fa-4b9d-9c14-0493c87ac262',
            maskedEmail: 'e******l@esusu.org',
            maskedPhoneNumber: '',
            isSignedUp: true,
            shouldGetFullSSN: true,
          }),
        timeOut,
      );
    }),
  updateResidentConsent: () =>
    new Promise<ConsentAPIResponseType>((resolve) => {
      setTimeout(
        () =>
          resolve({
            code: 'test',
            status: 'test',
            message: 'test',
          }),
        timeOut,
      );
    }),
  showCreditScore: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          ShowCreditScore: true,
          message: 'test',
        };
        resolve(data);
      }, timeOut);
    });
  },
  createIndependentUser: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          firstName: 'test',
          lastName: 'test',
          phoneNumber: '+14765297645',
        };
        resolve(data);
      }, timeOut);
    });
  },
  updateAddress: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          street: 'test',
          city: 'test',
          state: 'test',
          postalCode: 'test',
          unit: 'test',
          message: 'test',
        };
        resolve(data);
      }, timeOut);
    });
  },
  updateName: () => {
    return new Promise<unknown>((resolve) => {
      setTimeout(() => resolve([]), timeOut);
    });
  },
  updateSsn: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          ssn: 'test',
        };
        resolve(data);
      }, timeOut);
    });
  },
  updatePhoneNumber: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = {
          transactionId: '5c43827b-1a24-4f29-bf08-336f88d97b78',
          correlationId: 'ef6eeb8a-f6d7-4146-869d-67ade721a83b',
          referenceTransactionId: '',
          timestamp: '0001-01-01T00:00:00',
          decision: 'Deny',
          originalTransactionId: '5c43827b-1a24-4f29-bf08-336f88d97b78',
        };
        resolve(data);
      }, timeOut);
    });
  },
  createAccount: () =>
    new Promise<CreateAccountAPIResponseType>((resolve) => {
      setTimeout(
        () =>
          resolve({
            id: 'test',
            message: 'test',
          }),
        timeOut,
      );
    }),
  GenOtpRequest: (_: string, method: string) =>
    new Promise<GenOtpResponse>((resolve) => {
      setTimeout(
        () =>
          resolve({
            message: 'test',
            method: method,
          }),
        timeOut,
      );
    }),

  VerifyCode: () =>
    new Promise<verificationResponse>((resolve) => {
      setTimeout(
        () =>
          resolve({
            address: {
              city: 'test',
              countryCode: 'test',
              line1: 'test',
              line2: 'test',
              postalCode: 'test',
              state: 'test',
            },
            verified: true,
          }),
        timeOut,
      );
    }),

  GetSessionInfo: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            address: {
              apartmentNumber: 'test',
              city: 'test',
              state: 'test',
              street: 'test',
              zipcode: 'test',
            },
            verified: true,
          }),
        timeOut,
      );
    }),

  GetCards: () =>
    new Promise<any>((resolve) => {
      setTimeout(() => resolve([]), timeOut);
    }),

  GetCreditScore: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            score: 0,
          }),
        timeOut,
      );
    }),

  SendAccountVerificationEmail: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            email: 'test@esusu.org',
            message: 'response',
          }),
        timeOut,
      );
    }),

  GetAccountInfo: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            addressLine1: 'test',
            addressLine2: 'test',
            email: 'test',
            emailVerified: true,
            firstName: 'test',
            lastName: 'test',
            phoneNumber: 'test',
          }),
        timeOut,
      );
    }),
  GetAvailableFeatures: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            addressLine1: 'test',
            addressLine2: 'test',
            email: 'test',
            emailVerified: true,
            firstName: 'test',
            lastName: 'test',
            phoneNumber: 'test',
          }),
        timeOut,
      );
    }),

  UpdateEmail: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            email: 'test',
          }),
        timeOut,
      );
    }),

  UpdatePassword: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            password: 'test',
            oldPassword: 'test',
          }),
        timeOut,
      );
    }),

  GetCreditScoreHistory: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            scores: {
              date: 'test',
              provider: 'test',
              score: 0,
            },
            optInTimestamp: 'test',
          }),
        timeOut,
      );
    }),

  GetRentersHistory: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() =>
        resolve({
          accountStatus: 'test',
          timestamp: 'test',
          openDate: 'test',
          closedDate: 'test',
          address: {
            street: 'test',
            unit: 'test',
            city: 'test',
            state: 'test',
            postalCode: 'test',
          },
          reportingHistory: {
            period: 'test',
            status: 'test',
          },
        }),
      );
    }),

  Logout: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, 3000)).then(() => resolve({}));
    }),
  GetBanners: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            active: false,
            dateStart: '',
            dateEnd: '',
            header: 'test',
            img: 'test',
            content: 'test',
            button: false,
            buttonText: 'test',
            buttonLink: 'test',
            buttonNewTab: false,
            additionalContent: 'test',
            mtk: 'test',
          }),
        timeOut,
      );
    }),

  GetPaymentHistory: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() =>
        resolve({
          leaseStart: 'test',
          leaseEnd: 'test',
          address: {
            street: 'test',
            unit: 'test',
            city: 'test',
            state: 'test',
            postalCode: 'test',
          },
          dateUpdated: 'test',
          payments: {
            date: 'test',
            amountPaid: 0,
            amountOwed: 0,
            status: 'test',
          },
          paymentHistorySummary: {
            totalCompleted: 0,
            length: 0,
            monthlyRentalObligation: 0,
          },
        }),
      );
    }),
  RegisterApril: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            url: 'test',
          }),
        timeOut,
      );
    }),
  GeneratePdf: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() =>
        resolve({
          recepientEmail: ['test'],
        }),
      );
    }),
  deleteAccount: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() => resolve({}));
    }),
  getCreditScoreFactors: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() => resolve({}));
    }),
  getBrazeSubscriptions: () =>
    new Promise<any>((resolve) => {
      setTimeout(
        () =>
          resolve({
            email: [],
            sms: [],
            push: [],
          }),
        timeOut,
      );
    }),
  updateBrazeSubscriptions: () =>
    new Promise<any>((resolve) => {
      new Promise((resolve) => setTimeout(resolve, timeOut)).then(() => resolve({}));
    }),
};

const API: IAPI = {
  GetSampleAPI: (requestData: SampleAPIRequestType) => {
    const url = `/owners/${requestData.id}/data`;
    return request({ url, method: 'GET' });
  },
  GetEnrollmentStatus: () => {
    const url = '/enrollment/status';
    return request({ url, method: 'GET' });
  },
  GetSmfaVerificationStatus: (sessionId: string) => {
    const url = `/verification/smfa/status/${sessionId}`;
    return request({ url, method: 'GET' });
  },
  GetSSNStatus: () => {
    const url = '/account/ssn';
    return request({ url, method: 'GET' });
  },
  GetSMFAOptions: () => {
    const url = '/verification/options';
    return request({ url, method: 'GET' });
  },
  InitiateSMFA: (initiateSMFARequest: InitiateSMFARequestType) => {
    const url = '/verification/smfa';
    return request({ url, method: 'POST', data: initiateSMFARequest });
  },
  ValidateUser: (userData: UserData) => {
    const url = `/account/create`;
    return request({
      headers: { collection: 'owners' },
      url,
      method: 'POST',
      data: userData,
    });
  },
  createIndependentUser: (createIndependentUserData: IndependentUserAPIRequestType) => {
    const url = `/account/independent/create`;
    return request({ url, method: 'POST', data: createIndependentUserData });
  },
  updateResidentConsent: (consentData: ConsentAPIRequestType) => {
    const url = `/enrollment/status`;
    return request({ url, method: 'PATCH', data: consentData });
  },
  showCreditScore: (showScoreData: ShowScoreAPIRequestType) => {
    const url = `/credit/consent`;
    return request({ url, method: 'PATCH', data: showScoreData });
  },
  updateAddress: (updateAddressData: UpdateAddressAPIRequestType) => {
    const url = `/account/address`;
    return request({ url, method: 'PATCH', data: updateAddressData });
  },
  updateName: (updateName: UpdateNameAPIRequestType) => {
    const url = `/account/info`;
    return request({ url, method: 'PATCH', data: updateName });
  },
  updateSsn: (updateSsnData: UpdateSsnAPIRequestType) => {
    const url = `/account/ssn`;
    return request({ url, method: 'PATCH', data: updateSsnData });
  },
  updatePhoneNumber: (updatePhoneNumberData: UpdatePhoneNumberAPIRequestType) => {
    const url = `/account/phone`;
    return request({ url, method: 'PATCH', data: updatePhoneNumberData });
  },
  createAccount: (sessionId: CreateAccountAPIRequestType) => {
    const url = `/signup/create`;
    return request({ url, method: 'POST', data: sessionId });
  },
  GenOtpRequest: (id: string, method: string) => {
    const url = `/signup/otp`;
    return request({
      url,
      method: 'POST',
      data: {
        id,
        method,
      },
    });
  },
  VerifyCode: (id: string, method: string, otp: string) => {
    const url = `/signup/otp/verify`;
    return request({
      url,
      method: 'POST',
      data: {
        id,
        method,
        otp,
      },
    });
  },

  GetSessionInfo: (id: string) => {
    const url = `/signup/session/${id}`;
    return request({ url, method: 'GET' });
  },
  GetCreditScore: () => {
    const url = '/credit/report/';
    return request({ url, method: 'GET' });
  },
  GetPaymentHistory: () => {
    const url = '/payment-history/';
    return request({ url, method: 'GET' });
  },
  SendAccountVerificationEmail: () => {
    const url = '/account/verification/email';
    return request({ url, method: 'GET' });
  },
  GetAccountInfo: () => {
    const url = '/account/info/';
    return request({ url, method: 'GET' });
  },
  GetAvailableFeatures: () => {
    const url = '/account/available-features/';
    return request({ url, method: 'GET' });
  },
  GetRentersHistory: () => {
    const url = '/rr-history/';
    return request({ url, method: 'GET' });
  },
  UpdateEmail: (updateEmailData: UpdateEmailRequestType) => {
    const url = '/account/email';
    return request({ url, method: 'PATCH', data: updateEmailData });
  },

  UpdatePassword: (updatePasswordData: UpdatePasswordRequestType) => {
    const url = '/account/password';
    return request({ url, method: 'PATCH', data: updatePasswordData });
  },
  Logout: () => {
    const url = `/account/logout`;
    return request({ url, method: 'POST' });
  },

  GetCreditScoreHistory: (
    startTimestamp: string,
    endTimestamp: string,
    sort: string,
    limit: number,
  ) => {
    const url = `/credit/history?${qs.stringify(
      { startTimestamp: startTimestamp, sort: sort, limit: limit },
      { skipEmptyString: true, skipNull: true },
    )}`;
    return request({ url, method: 'GET' });
  },
  GetCards: () => {
    const url = '/card';
    return request({ url, method: 'GET' });
  },
  GetBanners: () => {
    const url = '/banner';
    return request({
      url,
      method: 'GET',
      params: {
        client: 'web',
      },
    });
  },
  RegisterApril: () => {
    const url = '/april/register';
    return request({ url, method: 'POST' });
  },
  GeneratePdf: (recepientEmail: GeneratePdfRequestType) => {
    const url = '/payment-history/send-pdf';
    return request({
      url,
      method: 'POST',
      data: recepientEmail,
    });
  },
  deleteAccount: (encryptedPassword: string) => {
    const url = '/v2/account';
    return request({
      url,
      method: 'DELETE',
      params: {
        password: encryptedPassword,
      },
    });
  },
  getCreditScoreFactors: () => {
    const url = '/credit-factors';
    return request({ url, method: 'GET' });
  },
  getBrazeSubscriptions: () => {
    const url = '/braze/subscriptions';
    return request({ url, method: 'GET' });
  },
  updateBrazeSubscriptions: ({
    status,
    subscriptionGroupId,
    notificationChannel,
  }: UpdateBrazeSubscriptionsRequest) => {
    const url = '/braze/subscriptions';
    return request({
      url,
      method: 'POST',
      data: [
        {
          status,
          subscriptionGroupId,
          channelName: notificationChannel,
        },
      ],
    });
  },
};
export default API;
export const testAPI = TestAPI;
